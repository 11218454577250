// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvvXIpZESDxjuDEm7PbVhQZcARViEMuKE",
  authDomain: "zeta-envoy-379817.firebaseapp.com",
  projectId: "zeta-envoy-379817",
  storageBucket: "zeta-envoy-379817.appspot.com",
  messagingSenderId: "711731446035",
  appId: "1:711731446035:web:172753c971476e812adae6",
  measurementId: "G-HD9K61N5PW"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

