import React, { useEffect, useRef, useState, useContext } from 'react';
import Slider from "react-slick";
import './style.css';
import { Link } from 'react-router-dom';

import { MyContext } from '../../App';
const CatSlider = ({ data, type, categories, products }) => {

    const [allData, setAllData] = useState(data);
    const [totalLength, setTotalLength] = useState([]);
    const context = useContext(MyContext);

    const [itemBg, setItemBg] = useState([
        '#fffceb',
        '#ecffec',
        '#feefea',
        '#fff3eb',
        '#fff3ff',
        '#f2fce4',
        '#feefea',
        '#fffceb',
        '#feefea',
        '#ecffec',
        '#feefea',
        '#fff3eb',
        '#fff3ff',
        '#f2fce4',
        '#feefea',
        '#fffceb',
        '#feefea',
        '#ecffec'
    ]);

    const slider = useRef();


    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        fade: false,
        // arrows: context.windowWidth > 920 ? true : false,
        // autoplay: context.windowWidth > 920 ? 2000 : false,
        // centerMode: context.windowWidth > 920 ? true : false
    };


    var catLength = 0;
    var lengthArr = [];
    useEffect(() => {
        // allData.length !== 0 &&
        //     allData.map((item, index) => {
        //         item.items.length !== 0 &&
        //             item.items.map((item_) => {
        //                 catLength += item_.products.length
        //             })
        //         lengthArr.push(catLength)
        //         catLength = 0;
        //     })

        // type.length !== 0 &&

        type.map((item, index) => {
            // categories
            //     .filter((e) => e.type === item._id).length !== 0 &&
            // categories
            //     .filter((e) => e.type === item._id).map((cat) => {
            //         catLength += products.filter((e) => e.category === cat._id).length
            //     })
            lengthArr.push(categories.filter((e) => e.type === item._id).length)
            catLength = 0;
        })

        const list = lengthArr.filter((item, index) => lengthArr.indexOf(item) === index);
        setTotalLength(list)


    }, []);


    return (
        <>
            <div className='catSliderSection'>
                <div className='container-fluid' ref={slider}>
                    <h2 className='hd'>Онцолсон Категориуд</h2>
                    <Slider {...settings} className='cat_slider_Main' id="cat_slider_Main" >
                        {
                            type.length !== 0 &&
                            type.filter((e) => e.special === true)
                                .map((item, index) => {
                                    return (
                                        <div className='item' key={index} style={{ width: '150px', height: '150px' }}>
                                            <Link to={`/cat/${item._id}`}>
                                                <div className='info' style={{ background: itemBg[index] }}>
                                                    <img src={'https://erhem.templateapi.xyz/uploads/' + item.image} width="80" height='80' />
                                                    <h5 className='text-capitalize mt-3'>{item.cat_name}</h5>
                                                    <p>{totalLength[index]} аниглалтай</p>
                                                </div>
                                            </Link>

                                        </div>
                                    )
                                })
                        }







                        {
                            //    itemBg.length!==0 && itemBg.map((item,index)=>{
                            //     return(
                            //         <div className='item'>
                            //         <div className='info'  style={{background:item}}>
                            //             <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-13.png'/>
                            //             <h5>Cake & Milk</h5>
                            //             <p>26 items</p>
                            //         </div>
                            //     </div>
                            //     )
                            //    }) 
                        }


                        {/* <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-12.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>

                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-11.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-9.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-3.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-1.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-2.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-4.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-13.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-5.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>

                        
                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-2.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-4.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-13.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div>


                        <div className='item'>
                            <div className='info'>
                                <img src='https://wp.alithemes.com/html/nest/demo/assets/imgs/shop/cat-5.png'/>
                                <h5>Cake & Milk</h5>
                                <p>26 items</p>
                            </div>
                        </div> */}


                    </Slider>
                </div >
            </div >



        </>
    )
}

export default CatSlider;