import React, { useEffect, useState, useContext } from 'react';
import './style.css';
import Rating from '@mui/material/Rating';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';


import { MyContext } from '../../App';


const Product = (props) => {

    const [productData, setProductData] = useState(props.item);
    const [isAdded, setIsadded] = useState(false);
    const [wishlistItems, setWishlistItems] = useState([]);

    const context = useContext(MyContext);

    useEffect(() => {
        // Load wishlist items from localStorage
        const storedWishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));
        if (storedWishlistItems) {
            setWishlistItems(storedWishlistItems);
        }
    }, []);

    const addToWishlist = (item) => {
        // Check if the item is already in the wishlist
        if (!wishlistItems.find((wishlistItem) => wishlistItem._id === item._id)) {
            const newWishlistItems = [...wishlistItems, item];
            setWishlistItems(newWishlistItems);
            // Update localStorage with the new wishlist items
            localStorage.setItem('wishlistItems', JSON.stringify(newWishlistItems));
            setIsadded(true);
        }
    };

    const setProductCat = () => {
        sessionStorage.setItem('parentCat', props.categories.filter((e) => e._id === productData.category).map((e) => (e.type)));
        sessionStorage.setItem('subCatName', props.categories.filter((e) => e._id === productData.category).map((e) => (e.cat_name)));
    }


    // const addToCart = (item) => {
    //     context.addToCart(item);
    //     setIsadded(true);
    // }


    return (
        <div className='productThumb' onClick={setProductCat}>
            {
                props.tag !== null && props.tag !== undefined &&
                <span className={`badge ${props.tag}`}>{props.tag}</span>
            }

            {
                productData !== undefined &&
                <>
                    <Link to={`/product/${productData._id}`}>
                        <div className='imgWrapper'>
                            <div className='p-4 wrapper mb-3'>
                                <img src={'https://erhem.templateapi.xyz/' + productData.productImages[0]} className='w-100' />
                            </div>

                            <div className='overlay transition'>
                                <ul className='list list-inline mb-0'>
                                    <li className='list-inline-item'>
                                        <a className='cursor' tooltip="Add to Wishlist">
                                            <FavoriteBorderOutlinedIcon />
                                        </a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a className='cursor' tooltip="Compare">
                                            <CompareArrowsOutlinedIcon />
                                        </a>
                                    </li>
                                    <li className='list-inline-item'>
                                        <a className='cursor' tooltip="Quick View">
                                            <RemoveRedEyeOutlinedIcon />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </Link>

                    <div className='info'>
                        <span className='d-block catName'>{
                            props.brands.filter((e) => e._id === productData.brand).map((e) => (e.name))
                        }</span>
                        <h4 className='title'><Link>{productData.productName.substr(0, 50) + '...'}</Link></h4>
                        <Rating name="half-rating-read"
                            value={parseFloat(productData.rating)} precision={0.5} readOnly />
                        <span className='brand d-block text-g'>By <Link className='text-g'>{
                            props.brands.filter((e) => e._id === productData.brand).map((e) => (e.name))
                        }</Link></span>
                        <div className='d-flex align-items-center mt-3'>
                            <div className='d-flex align-items-center w-100'>
                                <span className='price text-g font-weight-bold'>
                                    {productData.weight[0].price}₮</span>
                                {/* <span className='oldPrice ml-auto'>{productData.oldPrice}₮</span> */}
                            </div>
                        </div>

                        <Button className='w-100 transition mt-3'
                            onClick={() => {
                                addToWishlist(productData);
                            }}>
                            <ShoppingCartOutlinedIcon />
                            {
                                isAdded === true ? 'Захиалга өгөх хэсэгт нэмэгдсэн' : 'Захиалга өгөх'
                            }
                        </Button>

                    </div>

                </>
            }
        </div>
    )
}

export default Product;